import { Component, OnInit ,Input, Output,EventEmitter} from '@angular/core';
import { CategoriesService } from '../../services/categories.service';
import { MessageService } from '../../services/message-service.service';

import { MainCategory } from '../../models/MainCategory';
import { HostListener } from '@angular/core';

import { AppService } from '../../app.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  isOpen: boolean;
  categories: MainCategory[];
  openedList = 0;
  texts: any;
  textsSet: boolean;

  @Output() onFilter = new EventEmitter();

  constructor(
    private app: AppService,
    private categoriesService: CategoriesService,
    private _messageService: MessageService
  ) {
    this.textsSet = false;
    app.getTexts((texts)=>{
      this.textsSet = true;
      this.texts = texts;
    });

    this.isOpen= false;
    
    let last_updated_at = localStorage.getItem('categories_updated_at');
    let date = new Date();
    let day = date.getDate();

    //console.log(parseInt(last_updated_at));
    if( day> parseInt(last_updated_at) || (day === 1 && day < parseInt(last_updated_at)) ||  last_updated_at===null){
      this.categoriesService.getCategories().subscribe(categories => { 
        //console.log(categories);
        localStorage.setItem('categories', JSON.stringify(categories));
        localStorage.setItem('categories_updated_at', JSON.stringify(day));
        this.categories = categories;
      });
    }else{
      this.categories = JSON.parse(localStorage.getItem('categories'));
    }
    
   }

  ngOnInit() {
  }
  clickCategory(id):void {
    // this.onFilter.emit('Register click');
    this._messageService.filter(id);
  }
  toggleMenu(){
    //console.log("togle");
    this.isOpen = (this.isOpen===true)? false : true;
  }
  openChild(id){
   this.openedList = id;
  }
  @HostListener('document:click', ['$event']) clickedOutside(event){
    // here you can hide your menu
    //console.log(event);
    var path = event.path || (event.composedPath && event.composedPath());
    //console.log(path);
    if((!path[4] || path[4].id !="mainMenuNav") && (!path[5] || path[5].id !="mainMenuNav")){
      if(this.isOpen === true){
         this.toggleMenu();
      }
      
     
    }
  }
  showTouchKeyboard() {   
    this.app.showKeyboard();    
  }


}
