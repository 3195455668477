import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overall-a',
  templateUrl: './overall-a.component.html',
  styleUrls: ['./overall-a.component.css']
})
export class OverallAComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
