import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { CartProduct } from '../models/CartProduct';
import { FeedCart } from '../models/FeedCart';
import { CartAddResponse } from '../models/CartAddResponse';
@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private app: AppService, private http: HttpClient) { }

  sendQrCode(code: string){
    let url = this.app.server + 'ro_RO_1/api/comanda/finalizare/';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const message = {data:code}
    console.log(message);
    return this.http.post(url, JSON.stringify(message),{headers: headers, withCredentials : true});
  }
  addToCart(product: CartProduct) : Observable<CartAddResponse>{
    const headers = new HttpHeaders();
    let url = this.app.server + 'ro_RO_1/api/comanda/addToCart';
    headers.append('Content-Type', 'application/json');
    console.log(product);
    return this.http.post<CartAddResponse>(url, JSON.stringify(product),{headers: headers, withCredentials : true});
  }
  getCart() : Observable<FeedCart>{ 
    let url = this.app.server + 'ro_RO_1/api/comanda/veziCos/';
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/products/';
   console.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<FeedCart>(url, {headers: headers, withCredentials : true});
  }
  updateCart(product: CartProduct) : Observable<CartAddResponse>{
    const headers = new HttpHeaders();
    let url = this.app.server + 'ro_RO_1/api/comanda/updateQuantity';
    headers.append('Content-Type', 'application/json');
    return this.http.put<CartAddResponse>(url, JSON.stringify(product),{headers: headers, withCredentials : true});
  }
  removeProduct(product: CartProduct) : Observable<CartAddResponse>{
    const headers = new HttpHeaders();
    let url = this.app.server + 'ro_RO_1/api/comanda/remove/'+product.barCode;
    headers.append('Content-Type', 'application/json');
    return this.http.delete<CartAddResponse>(url,{headers: headers, withCredentials : true});
  }
}
