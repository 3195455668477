import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private app: AppService, private http: HttpClient) { }
  login(username: String, password: String){
    const headers = new HttpHeaders();
    let url = this.app.server + 'ro_RO_1/api/cont/auth/';
    headers.append('Content-Type', 'application/json');
    let credentials = {authEmail: username, authPass: password}
    //console.log(credentials)
    return this.http.post<any>(url, JSON.stringify(credentials),{headers: headers, withCredentials : true});
  }
  loginQr(code: String){
    const headers = new HttpHeaders();
    let url = this.app.server + 'ro_RO_1/api/cont/authQr/';
    headers.append('Content-Type', 'application/json');
    let credentials = {data: code}
    //console.log(credentials)
    return this.http.post<any>(url, JSON.stringify(credentials),{headers: headers, withCredentials : true});
  }
  logout(){
    const headers = new HttpHeaders();
    let url = this.app.server + 'ro_RO_1/api/cont/logout/';
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url,{headers: headers, withCredentials : true});
  }
  getMenu(){
    let url = this.app.server + 'ro_RO_1/api/cont/personal/';
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/cont/personal/';
   //console.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {headers: headers, withCredentials : true});
  }
  getInfo(){
    let url = this.app.server + 'ro_RO_1/api/cont/info/';
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/cont/personal/';
   //console.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {headers: headers, withCredentials : true});
  }
  getOrders(){
    let url = this.app.server + 'ro_RO_1/api/cont/orders/';
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/cont/orders/';
   //console.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {headers: headers, withCredentials : true});
  }
  getOrder(id){
    let url = this.app.server + 'ro_RO_1/api/cont/order/'+id;
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/cont/order/'+id;
   //console.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {headers: headers, withCredentials : true});
  }
  getWishlist(page){
    let url = this.app.server + 'ro_RO_1/api/cont/wishlist/'+page;
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/cont/order/'+id;
   //onsole.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {headers: headers, withCredentials : true});
  }
  getAddress(){
    let url = this.app.server + 'ro_RO_1/api/cont/adress/';
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/cont/order/'+id;
   //console.log(url);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(url, {headers: headers, withCredentials : true});
  }
}
