import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListProductsComponent } from './components/list-products/list-products.component';
import { StandbyComponent } from './components/standby/standby.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { SearchComponent } from './components/search/search.component';
import { ScanQrComponent } from './components/scan-qr/scan-qr.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { CartComponent } from './components/cart/cart.component';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserOrdersComponent } from './components/user-orders/user-orders.component';
import { UserOrderComponent } from './components/user-order/user-order.component';
import { UserAddressComponent } from './components/user-address/user-address.component';
import { UserWishlistComponent } from './components/user-wishlist/user-wishlist.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { SalesComponent } from './components/sales/sales.component';
import { NewProductsComponent } from './components/new-products/new-products.component';

const appRoutes: Routes = [
  { path: '',
  redirectTo: '/standby',
  pathMatch: 'full' },
  { path: 'standby',  component: StandbyComponent  },
  { path: 'homepage',  component: HomepageComponent  },
  { path: 'list/:category/:page',  component: ListProductsComponent },
  { path: 'list/:category',  component: ListProductsComponent },
  { path: 'list',  component: ListProductsComponent },
  { path: 'search/:term',  component: SearchComponent  },
  { path: 'qrscan/:page',  component: ScanQrComponent  },
  { path: 'product/:id',  component: ProductDetailsComponent  },
  { path: 'cart',  component: CartComponent  },
  { path: 'signup',  component: SignupComponent  },
  { path: 'login',  component: LoginComponent  },
  { path: 'user-menu',  component: UserMenuComponent  },
  { path: 'user-orders',  component: UserOrdersComponent  },
  { path: 'user-order/:id',  component: UserOrderComponent  },
  { path: 'user-address',  component: UserAddressComponent  },
  { path: 'user-wishlist',  component: UserWishlistComponent  },
  { path: 'user-wishlist/:page',  component: UserWishlistComponent  },
  { path: 'user-info',  component: UserInfoComponent  },
  { path: 'user-info',  component: UserInfoComponent  },
  { path: 'sales',  component: SalesComponent  },
  { path: 'sales/:page',  component: SalesComponent  },
  { path: 'new-products',  component: NewProductsComponent  },
  { path: 'new-products/:page',  component: NewProductsComponent  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(appRoutes,{onSameUrlNavigation: 'reload'})]
})

export class AppRoutingModule { }
