import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { MainCategory } from '../models/MainCategory';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor (private app: AppService, private http: HttpClient) {}

  getCategories() : Observable<MainCategory[]> { 
    //let url = 'http://localhost/webapis/';
    //let url = 'http://188.247.131.196/backend/ro_RO_1/api/categories/getcategories';

    let url = this.app.server + 'ro_RO_1/api/categories/getcategories';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<MainCategory[]>(url, {headers: headers, withCredentials : true});
  }

}
