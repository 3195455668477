import {Component, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {QrScannerComponent} from 'angular2-qrscanner';
import { ActivatedRoute,Router } from '@angular/router';

import { OrdersService } from '../../services/orders.service';
import { AppService } from '../../app.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ScanQrComponent implements OnInit {

    page: string;
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
  constructor(
    private app: AppService,
    private ordersService:OrdersService,     
    private userService: UserService,
    private route: ActivatedRoute,
     private router:Router) { }

  ngOnInit() {
    this.page = this.route.snapshot.paramMap.get('page');
    console.log(this.page);
    this.qrScannerComponent.getMediaDevices().then(devices => {
      //console.log(devices);
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
          if (device.kind.toString() === 'videoinput') {
              videoDevices.push(device);
          }
      }
      if (videoDevices.length > 0){
          let choosenDev;
          for (const dev of videoDevices){
              if (dev.label.includes('front')){
                  choosenDev = dev;
                  break;
              }
          }
          if (choosenDev) {
              this.qrScannerComponent.chooseCamera.next(choosenDev);
          } else {
              this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
          }
      }
  });

  this.qrScannerComponent.capturedQr.subscribe(result => {

      console.log("Result: " + result);
      if(this.page==='order'){
        this.ordersService.sendQrCode(result).subscribe(message => {
            console.log(message);
        });
      }else if(this.page === 'login'){
        this.userService.loginQr(result).subscribe( (msg) => {
            if(msg.status === 'ok'){
              console.log(msg);
              this.router.navigateByUrl('/user-menu');
            }
            console.log(msg);
          })
          
      }
      
  });
  }

}
