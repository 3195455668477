import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  texts: any;
  textsSet: boolean;
  user: User;
  constructor( private app: AppService,
    private userService: UserService,
    private router: Router) { 
      this.textsSet = false;
      app.getTexts((texts)=>{
        this.textsSet = true;
        this.texts = texts;
      });
      userService.getInfo().subscribe((msg)=>{
        if(msg.status !== 'ok' || msg.logged_in !== "true"){
          this.router.navigateByUrl('/login');
        }
        console.log(msg)
        this.user = msg.userInfo;
      });
    }

  ngOnInit() {
  }
  hasProp(o, name) {
    return o.hasOwnProperty(name);
  }

}
