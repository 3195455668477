import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, APP_BASE_HREF, LocationStrategy, HashLocationStrategy} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {NgxChildProcessModule} from 'ngx-childprocess';
import { NgQrScannerModule } from 'angular2-qrscanner';


import { AppComponent } from './app.component';
import { ListProductsComponent } from './components/list-products/list-products.component';

import { ProductsService } from './services/products.service';
import { AppService } from './app.service';
import { CategoriesService } from './services/categories.service';
import { MessageService } from './services/message-service.service';

import { StandbyComponent } from './components/standby/standby.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { OverallAComponent } from './components/overall-a/overall-a.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { SearchComponent } from './components/search/search.component';

import { ScanQrComponent } from './components/scan-qr/scan-qr.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { CartComponent } from './components/cart/cart.component';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
// Import your library
import { OwlModule } from 'ngx-owl-carousel';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserOrdersComponent } from './components/user-orders/user-orders.component';
import { UserOrderComponent } from './components/user-order/user-order.component';
import { UserAddressComponent } from './components/user-address/user-address.component';
import { UserWishlistComponent } from './components/user-wishlist/user-wishlist.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { SalesComponent } from './components/sales/sales.component';
import { NewProductsComponent } from './components/new-products/new-products.component';
@NgModule({
  declarations: [
    AppComponent,
    ListProductsComponent,
    StandbyComponent,
    NavbarComponent,
    OverallAComponent,
    HomepageComponent,
    SearchComponent,
    ScanQrComponent,
    ProductDetailsComponent,
    CartComponent,
    SignupComponent,
    LoginComponent,
    UserMenuComponent,
    UserOrdersComponent,
    UserOrderComponent,
    UserAddressComponent,
    UserWishlistComponent,
    UserInfoComponent,
    SalesComponent,
    NewProductsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    NgxChildProcessModule,
    NgQrScannerModule,
    OwlModule,
    Ng2CarouselamosModule
  ],
  providers: [    
    ProductsService,
    AppService,
    CategoriesService,
    MessageService,
    { provide: APP_BASE_HREF, useValue: '/' },
       { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
