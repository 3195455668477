import { Component, OnInit } from '@angular/core';
declare var jquer:any;
declare var $:any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  barcode:string;
  constructor() { }

  ngOnInit() {
  }
  checkBarcode(){
    console.log(this.barcode);
    if(this.barcode.length === 13){

    }else if(this.barcode.length >13){
      this.barcode = '';
    }
  }
  focusInput(){
    console.log("tests");
    $("#barcode").focus();
  }
}
