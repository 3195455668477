import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { CartProduct } from '../../models/CartProduct';
import { FeedCart } from '../../models/FeedCart';
import { Product } from '../../models/product';

import { OrdersService } from '../../services/orders.service';

declare var jquer:any;
declare var $:any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  products: Product[];
  productsLiked: Product[];
  productsRecommended: Product[];
  discount: number;
  newCartProduct: CartProduct;

  constructor
  (
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private location: Location) { 

      this.ordersService.getCart().subscribe(feed => {
        console.log(feed);
        this.products = feed.products;
        this.productsLiked = feed.productsLiked;
        this.productsRecommended = feed.productsRecommended;
        this.discount = feed.discount;
      } );
    }

  ngOnInit() {
  }
  
  removeProduct(idSku){
    if (confirm('Esti sigur ca vrei sa stergi produsul? ')) {
      this.newCartProduct = { barCode:idSku, quantity:0, options:'' };
      this.ordersService.removeProduct(this.newCartProduct).subscribe(msg => {
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id_sku === idSku) {
            this.products.splice(i, 1);
          }
        }
        console.log(msg);
        if(msg.status === 'ok'){
          $("#alertPopup").trigger("click");
          $("#infoPopup .fpw-content").html(msg.message);
        }else{
          $("#alertPopup").trigger("click");
          $("#infoPopup .fpw-content").html(msg.message);
        }
        
      });
    }
  }
  getStockArray(max){
    let numberArray = [];

    for(let i=1;i<=max;i++){
      numberArray.push(i);
    }
    return numberArray;

  }
  sumaProduse(){
    let suma:number;
    suma = 0;
    this.products.map((product) => {
      suma = suma + (parseFloat(product.sale_price) * product.quantity);
    });
    return suma.toFixed(2);
  }
  selectQuantity(quantity,barCode){
    this.newCartProduct = { barCode:barCode, quantity:quantity, options:'' };
    this.ordersService.updateCart(this.newCartProduct).subscribe(msg => {
    
      for (let i = 0; i < this.products.length; i++) {
        console.log(this.products[i]);
        if (this.products[i].unique_code === barCode) {
         
          this.products[i].quantity = quantity;
        }
        this.sumaProduse();
      }
      console.log(msg);
        if(msg.status === 'ok'){
          $("#alertPopup").trigger("click");
          $("#infoPopup .fpw-content").html(msg.message);
        }else{
          $("#alertPopup").trigger("click");
          $("#infoPopup .fpw-content").html(msg.message);
        }
        
    } );
  }

}
