import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap,Router  } from '@angular/router';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';

import { AppService } from '../../app.service';
import { ProductsService } from '../../services/products.service';
import { OrdersService } from '../../services/orders.service';

import { Product } from '../../models/product';
import { FeedProduct } from '../../models/FeedProduct';
import { CartProduct } from '../../models/CartProduct';

import {OwlCarousel} from 'ngx-owl-carousel';
declare var jquer:any;
declare var $:any;
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements  OnInit
{

  id: any;
  product: Product;
  productsLiked: Product[];
  productsLatestViewd: Product[];
  productsRecommended: Product[];
  sliderInit: boolean;
  lastElemIndex: Number;

  product$: Observable<FeedProduct>;
  productAddToCart: CartProduct;
  warningProduct: boolean;

  texts: any;
  textsSet: boolean;
  @ViewChild('owlElement') owlElement: OwlCarousel
  constructor(private app: AppService,
    private productsService: ProductsService, 
    private ordersService: OrdersService, 
    private route: ActivatedRoute,
    private location: Location, 
    private router: Router) { 
     this.sliderInit = false;
     this.lastElemIndex =0;
     this.productAddToCart = {'barCode':'',quantity:1,options:''}
     this.warningProduct = false;
     this.textsSet = false;
     app.getTexts((texts)=>{
       this.textsSet = true;
       this.texts = texts;
     });
    }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.setProduct(this.id);
   
  }

  setProduct(id:Number){
    this.productsService.getProduct(id ).subscribe(feed => {
      this.product = feed.product;
      this.productsLiked = feed.productsLiked;
      this.productsLatestViewd = feed.productsLatestViewd;
      this.productsRecommended = feed.productsRecommended;
      //console.log(this.productsLiked);
      
   } );
  }
  // Cand apasam pe un produs din caruselele de owl schimbam dinamic produsul cu this.setProduct(id);
  changeProduct(id){
    //this.router.navigate(['/product/'+id]);
  //  $('#productRecommended').remove();
   // this.owlElement.trigger('refresh.owl.carousel');
    this.setProduct(id);
  }

  // functia callSlider1 este apelaat de fiecare data cand se genereaza un element in ngFor la trackBy
  // o folosesc sa monitorizez indecsii si cand s-a terminat de incarcat lista aplica jquery ul
  // altfel nu se incarca sliderul cum trebuie. Din cauza ca angular lucreaza asincron, jquery-ul 
  // se incarca inainte sa se termine de adaugat toate imaginile, asa ca verific daca s-au incarcat toate si cand a ajuns la capat
  // initializez jquery ul. Cumva el reitereaza lista asa ca folosesc  (this.lastElemIndex>index) sa vad cand a luat-o de la inceput

  callSlider1(index){
    if(!this.sliderInit && (this.lastElemIndex>index)){
      var realThumbSlider=$("ul#bxslider-pager").bxSlider({
        minSlides: 4,
        maxSlides: 4,
        slideWidth: 156,
        slideMargin: 12,
        moveSlides: 1,
        pager:false,
        speed:1000,
        infiniteLoop:false,
        hideControlOnEnd:true,
        nextText:'<span></span>',
        prevText:'<span></span>',
        onSlideBefore:function($slideElement, oldIndex, newIndex){
          //$("#sliderThumbReal ul .active").removeClass("active");
         // $slideElement.addClass("active"); 
         
        }
      });
      var realSlider= $("ul#bxslider").bxSlider({
        speed:1000,
        pager:false,
        nextText:'',
        prevText:'',
        infiniteLoop:false,
        hideControlOnEnd:true,
        onSlideBefore:function($slideElement, oldIndex, newIndex){
          changeRealThumb(realThumbSlider,newIndex);
          
        }
        
      });
      

     
      $(".bx-next").removeAttr('href')
      this.sliderInit = true;
      linkRealSliders(realSlider,realThumbSlider);
    }
      
    
      if($("#bxslider-pager li").length<5){
        $("#bxslider-pager .bx-next").hide();
      }
      function linkRealSliders(bigS,thumbS){  
        $("ul#bxslider-pager").on("click",'a',function(event){
          
         // event.preventDefault();
           var newIndex=$(this).parent().attr("id").substring(7);
          // console.log("linkRealSliders " +  newIndex);
          bigS.goToSlide(newIndex);
        });
      }
      
      //slider!=$thumbSlider. slider is the realslider
      function changeRealThumb(slider,newIndex){  
       
        var $thumbS=$("#bxslider-pager");
        $thumbS.find('.active').removeClass("active");
        $thumbS.find('li[data-slideIndex="'+newIndex+'"]').addClass("active");
        
        if(slider.getSlideCount()-newIndex>=4)slider.goToSlide(newIndex);
        else slider.goToSlide(slider.getSlideCount()-4);
      }
      this.lastElemIndex = index;
  
  }

  owlSlider(index){
    //console.log(index);
  }
  imageSmall(image){
    return image.replace("xxl","list")
  }

  addToCart(){
    //console.log(this.productAddToCart);
    this.ordersService.addToCart(this.productAddToCart).subscribe(
      (msg) => {
        //console.log(msg);
        if(msg.status === 'ok'){
          $("#alertPopup").trigger("click");
          $("#infoPopup .fpw-content").html(msg.message);
        }
        
    });
  }
  showStar(stock,idProduct){
    let simbol ="";
    if(stock.currentGestion == 0){
      simbol="*";
      this.warningProduct=true;
    }
    return simbol;
  }
  showWarningStock(){
    return this.warningProduct;
  }
}
