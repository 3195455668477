import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AppService } from '../../app.service';
import { ProductsService } from '../../services/products.service';
import { OrdersService } from '../../services/orders.service';
import { MessageService } from '../../services/message-service.service';

import { Product } from '../../models/product';
import { CartProduct } from '../../models/CartProduct';

declare var jquer:any;
declare var $:any;
@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.css']
})
export class ListProductsComponent implements OnInit {
  products: Product[];
  idCategory: number;
  page: number;
  pagini: number;
  page_navigation_link_prefix: String;
  page_navigation_link_sufix: String;
  stpag: number;
  drpag: number;
  nr_pagini_afisate =3;
  show_fwd : boolean;
  show_rew : boolean;
  noPagesToShowLeft: Array<number>;
  noPagesToShowRight: Array<number>;

  productAddToCart: CartProduct;
  warningProducts: Array<number>;

  texts: any;
  textsSet: boolean;
  constructor(
    private app: AppService,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private location: Location,
    private _messageService: MessageService
  ) { 
      this.productAddToCart = {'barCode':'',quantity:1,options:''}
      this.warningProducts = [];
      this.textsSet = false;
      app.getTexts((texts)=>{
        this.textsSet = true;
        this.texts = texts;
      });
      
      //ASCULTAM EVENTUL PROVENIT DIN NAVBAR clickCategory, daca primeste un event, adica daca suntem deja pe pagina de listare si vrea sa vada alta categorie
      // trebuie sa declansam eventMenuChange(id_category)
      this._messageService.listen().subscribe((id:any) => {
        //console.log(id);
        this.eventMenuChange(id);
      })
    }

  ngOnInit() {
    this.idCategory = +this.route.snapshot.paramMap.get('category');
    this.page = +this.route.snapshot.paramMap.get('page');
    // console.log(this.idCategory);
    // console.log(this.page)
    if(!this.page){
      this.page= 1;
    }
    
    this.getProducts();
    
  }
  changePage(page){
    this.page = page;
    this.getProducts();
  }
  public eventMenuChange(idCategory){
    //console.log(this.idCategory);
    this.idCategory =idCategory;
    if(!this.page){
      this.page= 1;
    }
    
    this.getProducts();
  }

  getProducts(){
    this.productsService.getProducts(this.idCategory,this.page).subscribe(data => {
      this.products = data.products;
      this.pagini = data.pagini;
      this.page_navigation_link_prefix = "/list/"+this.idCategory+"/";
      this.page_navigation_link_sufix = "";
      //  console.log(this.page_navigation_link_prefix);
       this.determinePagination();
   } );
  }

  
  //Logica paginarii, luata din php si folosesc o functie ca sa le initializez
  determinePagination(){
    this.show_fwd = false;
    this.show_rew = false;
    if (this.pagini>1) {
      if (this.nr_pagini_afisate>=this.pagini) {
        this.stpag = 1;
        this.drpag = this.pagini;
      } else {
        this.stpag = this.page - Math.floor(this.nr_pagini_afisate/2);
        if (this.stpag<=1) {
          this.stpag = 1;
          this.drpag = this.nr_pagini_afisate;
          this.show_fwd = true;
        } else {
          this.show_rew = true;
          this.drpag = this.page + Math.ceil(this.nr_pagini_afisate/2)-1;
          if (this.drpag>=this.pagini) {
            this.drpag = this.pagini;
            this.show_fwd = false;
          } else {
            this.show_fwd = true;
          }
        }
      }
    }
    this.noPagesToShowLeft = this.generateNumbersArray(this.stpag,this.page);
    this.noPagesToShowRight = this.generateNumbersArray(this.page+1,this.drpag+1);
    // console.log("pagini: " + this.pagini);
    // console.log("nr_pagini_afisate: " + this.nr_pagini_afisate);
    // console.log("stpag: " + this.stpag);
    // console.log("drpag: " + this.drpag);
    // console.log("page: " + this.page);
    // console.log("show_fwd: " + this.show_fwd);
    // console.log("show_rew: " + this.noPagesToShowLeft);
    // console.log("show_rew: " + this.noPagesToShowRight);
    
  }

  // Generez o lista cu numere folosit la paginare, unde sunt for-urile
  generateNumbersArray(initial, final){
     let numbersArray = [];
      for(let i=initial; i<final; i++){
        numbersArray.push(i);
      }
      return numbersArray;
  }

  // Adaugarea in cos, cand se apeleaza formularul din pagina barcode-ul este adaugat obiectului this.productAddToCart
  // care paractic e {barCode: "xxxxxxxxxxx"}
  // pe asta il voi trimite catre php, similar unui ajax request, cum este in view-urile de php
  addToCart(){
    //console.log(this.productAddToCart);
    this.ordersService.addToCart(this.productAddToCart).subscribe(
      (msg) => {
        //console.log(msg);
        if(msg.status === 'ok'){
          $("#alertPopup").trigger("click");
          $("#infoPopup .fpw-content").html(msg.message);
        }
        
    });
  }
  showStar(stock,idProduct){
    let simbol ="";
    if(stock.currentGestion == 0){
      simbol="*";
      this.warningProducts.push(idProduct);
    }
    return simbol;
  }
  showWarningStock(idProduct){
    return (this.warningProducts.indexOf(idProduct)>=0)? true : false;
  }

  onFilterClick(event) {
     // console.log('Fire onFilterClick: ', event);
  }
}
