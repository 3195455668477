import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  ENVIRONMENT:String;
  texts: any;
  textsSet: boolean;
  constructor(
    private app: AppService,
    private userService: UserService,
    private router: Router
  ) {
    this.textsSet = false;
    this.ENVIRONMENT = this.app.ENVIRONMENT ;
    //console.log(  this.ENVIRONMENT );
    app.getTexts((texts)=>{
      this.textsSet = true;
      this.texts = texts;
     
    });
    userService.getMenu().subscribe((msg)=>{
      if(msg.status === 'ok' && msg.logged_in === "true"){
        this.router.navigateByUrl('/user-menu');
      }
    });
   }
  ngOnInit() {
  }
  checkEnvironment(){
    return (this.ENVIRONMENT !== 'development')?"off":"";
  }
  getYears(){
    let max = 2018;
    let numberArray = [];

    for(let i=max;i>=1930;i--){
      numberArray.push(i);
    }
    return numberArray;

    
  }
  showTouchKeyboard() {   
    this.app.showKeyboard();    
  }

}
