import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  ENVIRONMENT:String;
  texts: any;
  textsSet: boolean;
  authEmail: String;
  authPass: String;
  constructor(
    private app: AppService,
    private userService: UserService,
    private router: Router
  ) {
    this.textsSet = false;
    this.ENVIRONMENT = this.app.ENVIRONMENT ;
    app.getTexts((texts)=>{
      this.textsSet = true;
      this.texts = texts;
    });
    userService.getMenu().subscribe((msg)=>{
      if(msg.status === 'ok' && msg.logged_in === "true"){
        this.router.navigateByUrl('/user-menu');
      }
      //console.log(msg);
    });
   }

  ngOnInit() {
  }
  checkEnvironment(){
    return (this.ENVIRONMENT !== 'development')?"off":"";
  }
  login(){
    this.userService.login(this.authEmail,this.authPass).subscribe( (msg) => {
      console.log(msg);
      if(msg.status === 'ok'){
        //console.log(msg);
        this.router.navigateByUrl('/user-menu');
      }
    })
  }
  showTouchKeyboard() {   
    this.app.showKeyboard();    
  }

}
