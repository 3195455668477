import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppService } from './app.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  loadAPI: Promise<any>; //add this
  pageName: String;
  texts:any;
  constructor( private app: AppService, private http: HttpClient, private router: Router) {
    
  }
 
  // isStandByPage se foloseste in app.component.html ca sa nu mai afisez meniul in pagina de stand by
  isStandByPage() {
    const res = (this.router.url === '/standby') ? true : false;
    return res;
  }
  getPageName(){
    let urlSegments = this.router.url.split("/");
    //console.log( urlSegments);
    return urlSegments[1];
  }
  ngOnInit(){
    
  }
}
