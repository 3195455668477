import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap,Router  } from '@angular/router';

import { AppService } from '../../app.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { Order } from '../../models/order';
import { Product } from '../../models/Product';

@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.css']
})
export class UserOrderComponent implements OnInit {
  id: any;
  texts: any;
  textsSet: boolean;
  user: User;
  order: Order;
  products: Product[];
  constructor( private app: AppService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute) {
      this.textsSet = false;
      app.getTexts((texts)=>{
        this.textsSet = true;
        this.texts = texts;
      });
      
     }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    console.log(this.id);
    this.userService.getOrder(this.id).subscribe((msg)=>{
      if(msg.status !== 'ok' || msg.logged_in !== "true"){
        this.router.navigateByUrl('/login');
      }
      this.user = msg.userInfo;
      this.order = msg.order;
      this.products = msg.products;
      console.log(msg);
    });
  }
  hasProp(o, name) {
    return o.hasOwnProperty(name);
  }
}
