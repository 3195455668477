import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
declare var jquer:any;
declare var $:any;
@Component({
  selector: 'app-standby',
  templateUrl: './standby.component.html',
  styleUrls: ['./standby.component.css']
})
export class StandbyComponent implements OnInit {
  pageName:String;
  transition: boolean;
  constructor(
    private route: ActivatedRoute,
    private location: Location) { 
    this.pageName = "stand-by"
    this.transition=false;
  }

  ngOnInit() {  
    var margin = 1920/2 - $('.intro').outerHeight();
    $('.intro').css({'top' : margin});
    $('.intro').css({'height' : '800px'});
    $('.start').click(function() {
       
        event.stopPropagation();
         $(".start").fadeOut('slow');
        //$(".start").remove();
        $('.intro').fadeIn('slow');
       
        setTimeout( function() {
          console.log("intro");
            $('.intro').fadeOut('slow', function() {
                window.location.href = "#/homepage";
            });
        }, 3000);
    });
  }
  clickBanner(){
    this.transition=true;

    var margin = 1920/2 - $('.intro').outerHeight();
    $('.intro').css({'top' : margin});
    $('.start').click(function() {
       
        event.stopPropagation();
        //$(".start").fadeOut('slow');
        $('.intro').fadeIn('slow');
       
        setTimeout( function() {
          console.log("intro");
            $('.intro').fadeOut('slow', function() {
                window.location.href = "#/homepage";
            });
        }, 300000);
    });
  }
}
