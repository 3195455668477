import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import { map, filter, scan, } from 'rxjs/operators';
import { Product } from '../models/product';
import { FeedProduct } from '../models/FeedProduct';
import { FeedProducts } from '../models/FeedProducts';
import { AppService } from '../app.service';
@Injectable()
export class ProductsService {

  constructor (private app: AppService, private http: HttpClient) {}

  getProducts(idCategory: Number, page: Number) : Observable<FeedProducts>{ 
    let url = this.app.server + 'ro_RO_1/api/products/category/'+idCategory+'/'+page;
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/products/';
   
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<FeedProducts>(url, {headers: headers, withCredentials : true});
  }
  getProduct(id: Number) : Observable<FeedProduct>{ 
    let url = this.app.server + 'ro_RO_1/api/products/product/'+id;
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/products/';
   
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<FeedProduct>(url, {headers: headers, withCredentials : true});
  }
  getSales(page: Number) : Observable<FeedProducts>{ 
    let url = this.app.server + 'ro_RO_1/api/products/sales/'+page;
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/products/';
   
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<FeedProducts>(url, {headers: headers, withCredentials : true});
  }
  getNewProducts(page: Number) : Observable<FeedProducts>{ 
    let url = this.app.server + 'ro_RO_1/api/products/newProducts/'+page;
   //let url = 'http://188.247.131.196/backend/ro_RO_1/api/products/';
   
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<FeedProducts>(url, {headers: headers, withCredentials : true});
  }

}