import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule, HttpHandler  } from '@angular/common/http';
import { Router } from '@angular/router';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import {ChildProcessService} from 'ngx-childprocess';


@Injectable()
export class AppService {
  authenticated: boolean;
  checked = false;
  toogled = false;
  server : String;
  texts : any;
  ENVIRONMENT: String;
  constructor(private http: HttpClient, private router: Router,
    private _childProcessService: ChildProcessService) {
    this.ENVIRONMENT = "development";//development, testing, production
    this.server = "https://nissaexp.wbrt.ro/";  //http://188.247.131.196/backend/  https://nissaexp.wbrt.ro http://localhost/nissademo/
  }

  toggle() {
    this.toogled = (this.toogled === true ) ? false : true;
  }
  // checkTexts(callback){
  //   console.log("check Texts")
  //   this.getTexts().subscribe(texts => {
  //     console.log(texts)
  //     this.texts = texts;
  //     return callback && callback(texts);
  //   });
  // }

  // getTexts(callback)
  // scoatem textele, daca ele nu sunt salvate local sau nu au fost reimproasptate azi, se face rest call catre server
  // daca ele sunt stocat pe server si proaspete le luam din localStorage
  getTexts(callback){
    let url = this.server + 'ro_RO_1/feed/getTexts/';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let last_updated_at = localStorage.getItem('texts_updated_at');
    let date = new Date();
    let day = date.getDate();

    if( day> parseInt(last_updated_at) || (day === 1 && day < parseInt(last_updated_at)) ||  last_updated_at===null){
      this.http.get(url, {headers: headers}).subscribe(texts => {
        console.log('fetching texts form server');
        localStorage.setItem('texts', JSON.stringify(texts));
        localStorage.setItem('texts_updated_at', JSON.stringify(day));
        this.texts = texts;
        return callback && callback(texts);
      });;
    }else{
     
      let texts = localStorage.getItem('texts');
      //console.log(texts);
      return callback && callback(JSON.parse(texts));
    }
    
  }
  showKeyboard(){
    if(this._childProcessService.isElectronApp === true){
      console.log("WORKINg");
      this._childProcessService.childProcess.exec('start C:\\Windows\\System32\\osk.exe',[], (error, stdout, stderr) => {
        if (error) {
            console.error(error);
            return;
        }
    });
    }
  }

}
